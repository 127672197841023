import logo from './verdict-logo_1.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p><i>New website coming soon.</i></p>
        <div className="address">
          <div>
            <p><b>Silicon Valley/San Francisco Bay Area Office:</b></p>
            <p>96 North Third Street | Suite 500 | San Jose | California | 95112</p>
            <p>O 408.404.5000 | F 408.404.5010</p>
          </div>
          <div>
            <p><b>Austin Office:</b></p>
            <p>1005 Congress Avenue | Suite 925 | Austin | Texas | 78701</p>
            <p>O 512.253.7005 | F 512.253.7317</p>
          </div>
        </div>
        <a href="mailto:info@verdict-group.com">info@verdict-group.com </a>
      </header>
    </div>
  );
}

export default App;
